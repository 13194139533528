<script lang="ts">
  import { WebsiteName, WebsiteBaseUrl } from "../../../config"

  export let ldJson = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: WebsiteName,
    url: WebsiteBaseUrl,
  }

  //ugly hack to avoid the script end tag being recognised
  const jsonldScript = `<script type="application/ld+json">${JSON.stringify(ldJson) + "<"}/script>`
</script>

<!-- eslint-disable-next-line svelte/no-at-html-tags -->
{@html jsonldScript}
